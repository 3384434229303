import React from 'react'
import PropTypes from 'prop-types'
import Reference from '@frontastic/catwalk/src/js/component/reference'
import FrontasticImage from '../../atoms/frontasticImage'
import Button from '../../atoms/button/Button'

const BannerTile = ({
    title,
    desc,
    media,
    name,
    reference,
    btnLinkText,
    btnVariant,
    option,
    btnColorDefault,
    btnColorHover,
}) => {
    if (!media) {
        return null
    }

    return (
        <div className={'banner-tiles'}>
            <div className={'banner-tiles-image'}>
                <Reference reference={reference}>
                    <FrontasticImage
                        media={media}
                        title={name}
                        alt={name}
                    />
                </Reference>
            </div>
            <div className={'banner-tiles-content'}>
                {title && <h3>{title}</h3>}
                {option && desc ? <p>{desc}</p> : null}
                {btnLinkText
                    && (
                        <Button
                            className={`btn btn-${btnVariant} teaser-block--button btn--color-${btnColorDefault} btn--hover-${btnColorHover}`}
                            reference={reference}
                        >
                            <div className={'teaser-block--button-text'}>{btnLinkText}</div>
                        </Button>
                    )}
            </div>
        </div>
    )
}

BannerTile.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    name: PropTypes.string,
    media: PropTypes.object,
    reference: PropTypes.object,
    btnLinkText: PropTypes.string,
    btnVariant: PropTypes.string,
    btnColorDefault: PropTypes.string,
    btnColorHover: PropTypes.string,
    option: PropTypes.bool,
}

export default BannerTile

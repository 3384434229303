import { ImageProps } from 'next/image';
import { MediaTypes } from '../../../../types/images';

type NextImageProps = ImageProps & {
  cloudName?: string;
  mediaType?: MediaTypes;
  resolution?: number;
  rootDomain?: string;
  quality?: string | number;
};

const CLOUDINARY_TRANSFORMATIONS = ['f_auto', 'c_pad', 'q_auto', 'q_auto:best', 't_sde'];

const normalizeSrc = (src: string): string => (src[0] === '/' ? src.slice(1) : src);

const hasWidthOrHeightParam = (input: string): boolean => {
  const patternCheckHeight = /^h_\d+$/; // h_{number}
  const patternCheckWidth = /^w_\d+$/; // w_{number}
  const patternCheckWidthAndHeight = /^w_\d+,h_\d+$/; // w_{number},h_{number}
  const patternCheckHeightAndWidth = /^h_\d+,w_\d+$/; // h_{number},w_{number}

  return (
    patternCheckHeight.test(input) ||
    patternCheckWidth.test(input) ||
    patternCheckWidthAndHeight.test(input) ||
    patternCheckHeightAndWidth.test(input)
  );
};

const removeExistedTransformationAndFolderName = (src: string): string => {
  const splittedSrc: string[] = src.split('/');

  const srcRemovedWidthHeight: string[] = splittedSrc
    .filter((item) => !hasWidthOrHeightParam(item))
    .map((item) => item.replace(/,{2,}/g, ',').replace(/(^,)|(,$)/g, ''));

  const transformations = srcRemovedWidthHeight.map((item) =>
    item
      .split(',')
      .filter((subItem) => !CLOUDINARY_TRANSFORMATIONS.includes(subItem))
      .join(','),
  );
  return transformations.join('/');
};

const generateParamsForProduct = (customWidthByScreen: number, customHeightByScreen: number): string[] => [
  ...(customWidthByScreen ? [`w_${customWidthByScreen}`] : []),
  ...(customHeightByScreen ? [`h_${customHeightByScreen}`] : []),
];

const getQualityParam = (quality: number): string => {
  if (quality) {
    return `q_${quality}`;
  }

  return `q_auto:best`;
};

/*
 * If the image source already has transformations, append the new transformations
 * to the existing transformations
 * Image url example: https://res.cloudinary.com/schuhede-images/image/upload/f_auto,c_pad,q_auto:best,w_520,h_520/w_520,h_520/v1693494121/schuhede/1433528/1433528-04.png
 */
const getProductImageSrc = (rootDomain, src: string, width: number, height: number, quality: number): string => {
  const customWidthByScreen: number = Math.round(width);
  const customHeightByScreen: number = Math.round(height);
  const qualityParam: string = getQualityParam(quality);
  const originalSource: string = removeExistedTransformationAndFolderName(src);
  const transformations = generateParamsForProduct(customWidthByScreen, customHeightByScreen).join(',');

  return [rootDomain, 'f_auto', qualityParam, transformations, normalizeSrc(originalSource)]
    .filter((item) => !!item.trim())
    .join('/');
};

const ProductSchuheLoader = ({ src, width, height, mediaType, rootDomain, quality }: NextImageProps): string => {
  if (mediaType === 'PRODUCT') {
    return getProductImageSrc(rootDomain, src as string, width as number, height as number, quality as number);
  }

  // image source with the query parameters removed
  const source = (src as string).split('?')[0];

  return `${rootDomain}/${normalizeSrc(source)}`;
};

export default ProductSchuheLoader;

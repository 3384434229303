import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import BannerTile from './BannerTile'
import FullPageWidthWrapper from '../../molecules/Layout/FullPageWidthWrapper'

const BannerTiles = ({
    bannerLists, btnLinkText, btnVariant, bgColor, textColor, btnColorHover, btnColorDefault,
}) => {
    const lengthOfBannerList = bannerLists.length
    const renderBannerTile = () => bannerLists.map((item, index) => {
        const option = !(lengthOfBannerList > 2 && (index === 1 || index === 2))
        const { title, reference, desc } = item
        // const media = lengthOfBannerList > 2 ? item.image?.media : item.image

        return (
            <BannerTile
                key={title}
                btnLinkText={btnLinkText}
                btnVariant={btnVariant}
                btnColorHover={btnColorHover}
                btnColorDefault={btnColorDefault}
                title={title}
                desc={desc}
                media={item.image?.media}
                reference={reference}
                option={option}
            />
        )
    })

    const renderStoriesByDevice = () => (
        <div className={classnames('banner-tiles-wrapper hover-zoomin-image', {
            'banner-tiles-block': lengthOfBannerList > 2,
            'banner-two-item': lengthOfBannerList === 2,
        })}>
            {renderBannerTile()}
        </div>
    )

    const style = bgColor ? { backgroundColor: bgColor } : {}
    const styleText = textColor ? { color: textColor } : {}

    return (
        <>
            {bgColor ? (
                <FullPageWidthWrapper
                    customClasses={classnames('banner-tiles-component relative', {
                        'py-comp': bgColor,
                    })}
                    withWrapper
                    style={style}
                >
                    <div style={styleText} className={'banner-tiles-container'}>
                        {renderStoriesByDevice()}
                    </div>
                </FullPageWidthWrapper>
            ) : (
                <div style={styleText} className={'banner-tiles-container my-comp'}>
                    {renderStoriesByDevice()}
                </div>
            )}

        </>

    )
}

BannerTiles.propTypes = {
    bannerLists: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        desc: PropTypes.string,
        media: PropTypes.object,
    })),
    btnVariant: PropTypes.oneOf(['cta', 'secondary', 'default']),
    btnLinkText: PropTypes.string,
    bgColor: PropTypes.string,
    textColor: PropTypes.string,
    btnColorDefault: PropTypes.string,
    btnColorHover: PropTypes.string,
}

export default BannerTiles

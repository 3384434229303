import {ImageLoaderProps} from 'next/image'

interface ExtendedImageLoaderProps extends ImageLoaderProps {
    height?: number
}

function cloudinaryLoader({src, width, quality = 100, height}: ExtendedImageLoaderProps) {
    const cloudName = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME // cloudinary cloud name
    const root = `https://res.cloudinary.com/${cloudName}/image/upload` // base path

    const query = (src as string).split('?')[1] // contains the query paremeters
    const queries = new URLSearchParams(query) // injected query params

    const ratio = queries.get('ratio') // aspect asset ratio
    const gravity = queries.get('gravity') // required gravity: faces, body, etc..
    const x = queries.get('x__coord') // x coordinate for custom gravity
    const y = queries.get('y__coord') // y coordinate for custom gravity

    const customWidthByScreen = Math.round(+width * quality / 100) || Math.round(+width)
    const customHeightByScreen = Math.round(+height * quality / 100) || Math.round(+height)

    // cloudinary transformations
    const params = ratio
        ? [
            ...['f_auto', 'c_limit', `g_${gravity ?? 'auto'}`],
            ...(width ? [`w_${customWidthByScreen}`, 'q_auto', 'c_fill'] : []),
            ...(gravity === 'custom' ? [`x_${x}`, `y_${y}`] : []),
            ...([`ar_${ratio}`]),
        ]
        : [
            ...(width ? [`w_${customWidthByScreen}`, 'q_auto', 'c_scale', 'f_auto'] : []),
        ]

    const transformations = params.join(',')

    // image source with the query paremeters removed
    let source = src.split('?')[0]

    // remove the backslash at the beginning if it exists
    source = source[0] === '/' ? source.slice(1) : source

    return `${root}/${transformations}/${source}`
}

export default cloudinaryLoader

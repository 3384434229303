/**
 * Adds or updates query parameters in a URL.
 *
 * Example: addOrUpdateQueryParam('example.com?foo=bar', { foo: 'baz', qux: 'quux' }).
 * Returns: 'example.com?foo=baz&qux=quux'.
 *
 * @param {string} url - The URL to modify.
 * @param {Record<string, string>} queryParams - The query parameters to add or update.
 * @returns {string} The modified URL.
 */
export function addOrUpdateQueryParam(url: string, queryParams: Record<string, string>): string {
    const urlObj = new URL(url, window?.location?.origin);
    const existingParams = new URLSearchParams(urlObj.search);

    for (const [key, value] of Object.entries(queryParams)) {
        if (value) {
            // Set or update based on whether the key exists
            existingParams.set(key, value);
        } else {
            // Remove if the value is empty
            existingParams.delete(key);
        }
    }

    // Replace the URL's searchParams with the modified existingParams
    urlObj.search = existingParams.toString();
    return urlObj.toString();
}


export function deleteQueryParam(url, key) {
    const urlObj = new URL(url, window?.location?.origin) // Second argument is to handle relative URLs
    urlObj.searchParams.delete(key)
    return urlObj.toString()
}

/**
 * Gets a query parameter or all query parameters from a URL.
 *
 * @param {string} url - The URL to extract query parameters from.
 * @param {string} [paramName] - (Optional) The name of the specific parameter to retrieve.
 * If not provided, all parameters are returned.
 *
 * @returns {string | Record<string, string>} The value of the specified parameter or an object
 *containing all parameters as key-value pairs.
 */
export function getQueryParam(url: string, paramName?: string): string | Record<string, string> {
    const urlObj = new URL(url, window?.location?.origin);
    const params = new URLSearchParams(urlObj.search);

    if (paramName) {
        return params.get(paramName);
    } else {
        const allParams: Record<string, string> = {};
        params.forEach((value, key) => {
            allParams[key] = value;
        });
        return allParams;
    }
}

/**
 * Checks if a link is an anchor tag.
 *
 * @param {string} link - The link to check.
 * @returns {boolean} True if the link is an anchor tag, false otherwise.
 */
export function isAnchorTag(link: string): boolean {
    if (!link) {
        return false
    }

    return link.startsWith('#')
}
import React from 'react';
import NextImage from 'next/image';
import useDimensions from './hooks/useDimensions';
import useParameterizedSrc from './hooks/useParameterizedSrc';
import cloudinaryLoader from './loaders/cloudinary';
import defaultLoader from './loaders/default';
import ProductLoader from './loaders/product';
import { ImageProps } from '../../../types/images';

const DEFAULT_CLOUDINARY_DOMAIN_NAME = 'image.sport2000.de';
const DEFAULT_FOLDER_NAME = 't_s2k';
const DEFAULT_QUALITY_BLUR_IMAGE = 10;
const rootDomain = `https://${DEFAULT_CLOUDINARY_DOMAIN_NAME}/image/upload/${DEFAULT_FOLDER_NAME}`;

/*
 * Get the rest of the media url after the '/image/upload' string and remove default folder name
 * F.e: /v1678099631/1256097/1256097-01.png
 */
const getRestOfMedia = (src: string): string => {
  const restOfMedia = src?.split('/image/upload')?.[1]?.replace(/(\/t_s2k|\/q_auto|\/f_auto)/g, '') || '';
  // Remove the first item
  return restOfMedia.substring(1);
};

const FrontasticImage = ({
  media,
  ratio,
  gravity,
  suffix,
  src,
  width,
  height,
  title,
  loading = 'lazy',
  priority = false,
  alt = '',
  ...props
}: ImageProps) => {
  const dimensions = useDimensions({
    media,
    width,
    height,
    ...props,
  });
  const parameterizedSrc = useParameterizedSrc({
    ratio,
    gravity,
    suffix,
    media,
    src,
  });

  if (!media?.mediaId) {
    const imageSrc = ProductLoader({
      src: getRestOfMedia(src),
      width,
      height,
      mediaType: 'PRODUCT',
      rootDomain,
      quality: +props.quality,
      alt,
    });

    return (
      <NextImage
        src={imageSrc}
        loader={defaultLoader}
        alt={alt}
        title={title}
        priority={priority}
        loading={priority ? 'eager' : loading}
        className="mix-blend-multiply"
        {...dimensions}
        {...props}
      />
    );
  }

  return (
    <NextImage
      src={parameterizedSrc}
      loader={cloudinaryLoader}
      alt={alt}
      title={title}
      placeholder={priority ? 'empty' : 'blur'}
      blurDataURL={cloudinaryLoader({
        src: parameterizedSrc,
        width: +dimensions.width,
        quality: DEFAULT_QUALITY_BLUR_IMAGE,
      })}
      priority={priority}
      loading={priority ? 'eager' : loading}
      {...dimensions}
      {...props}
    />
  );
};

export default FrontasticImage;
